import { NgModule } from '@angular/core';
import { MatButtonModule, 
    MatCheckboxModule,
    MatToolbarModule,
    MatChipsModule,
    MatOptionModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDialogModule, 
    MatSnackBarModule, 
    MatSelectModule, 
    MatInputModule, 
    MatSidenavModule, 
    MatCardModule, 
    MatIconModule, 
    MatRadioModule, 
    MatProgressSpinnerModule, 
    MatTabsModule, 
    MatNativeDateModule,
    MatDatepickerModule,
    MatTableModule,
    MatListModule, 
    MatExpansionModule,
    MatFormFieldModule,
    MatSortModule,
    MatStepperModule,
    MatPaginatorModule } from '@angular/material';

@NgModule({
  imports: [
    MatButtonModule, 
    MatExpansionModule,
    MatCheckboxModule, 
    MatToolbarModule, 
    MatChipsModule, 
    MatIconModule,
    MatOptionModule, 
    MatGridListModule, 
    MatProgressBarModule, 
    MatSliderModule, 
    MatSlideToggleModule, 
    MatMenuModule, 
    MatDialogModule, 
    MatSnackBarModule, 
    MatSelectModule, 
    MatNativeDateModule,
    MatInputModule, 
    MatSidenavModule,
    MatDatepickerModule, 
    MatCardModule, 
    MatIconModule, 
    MatRadioModule, 
    MatProgressSpinnerModule, 
    MatTabsModule,
    MatTableModule,
    MatListModule,
    MatStepperModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSortModule,
    MatPaginatorModule 
  ],
  exports: [
    MatButtonModule, 
    MatExpansionModule,
    MatCheckboxModule, 
    MatToolbarModule, 
    MatChipsModule, 
    MatIconModule,
    MatOptionModule, 
    MatGridListModule, 
    MatProgressBarModule, 
    MatSliderModule, 
    MatSlideToggleModule, 
    MatMenuModule, 
    MatDialogModule, 
    MatSnackBarModule, 
    MatSelectModule, 
    MatInputModule, 
    MatSidenavModule, 
    MatCardModule, 
    MatIconModule, 
    MatRadioModule, 
    MatProgressSpinnerModule, 
    MatTabsModule, 
    MatListModule,
    MatStepperModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule 
  ],
})
export class MaterialModule { }