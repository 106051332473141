import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ViewPageComponent } from './view-page/view-page.component';


const routes: Routes = [
  // { path: '', component: HomepageComponent }
  { path: 'viewPage', component: ViewPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FacultyFilterRoutingModule { }
