import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../-api-service.service';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss']
})
export class ViewPageComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiServiceService,
              private route: Router) { }

  data: any;
  displayedColumns: string[] = ['one', 'two', 'three', 'four', 'five', 'six'];
  dataSource;
  nullValueMsg: any = 'No Records Found Please try again later....!';
  nullValueFlag: boolean;
  FacultyName: any;

  ngOnInit() {
    this.nullValueFlag = false;
    this.data = this.apiService.getSinglePersonData();
    if (this.data != null) {
      this.dataSource = this.data;
      this.FacultyName = this.data[0].FacultyName;
    } else {
      this.nullValueFlag = true;
    }
  }

  ngOnDestroy() {
    this.route.navigate(['']);
  }

}
