import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { EResourseHeader } from './homepage/homepage.component';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http: HttpClient, private route: Router) { }

    facultyDataForm = new FormGroup({
      SchoolName : new FormControl(''),
      DepartmentName : new FormControl(''),
      NameOfTheTeacher : new FormControl(''),
      ProgrameName : new FormControl(''),
      CourseName : new FormControl(''),
      Semester : new FormControl('')
    });

  singlePersonData: any = null;
  url = 'https://cukapi.korisan.in/ESK/EResourses/';

  getEResourseHeaderDefault() { return this.http.get( this.url + 'GetEResourseHeaderDefault'); }

  getEResourseHeader(data) { return this.http.post(this.url + 'GetEResourseHeader', data); }

  getEResourseDetail(Id) {
    return this.http.get(this.url + 'GetEResourseDetail?HeaderID=' + Id).subscribe(data => {
      this.singlePersonData = data;
      this.route.navigate(['/viewPage']);
    }, err => {
      console.log(err);
    });
  }

  getSinglePersonData() { return this.singlePersonData; }

  // fillNull = () => this.singlePersonData = null;
}
