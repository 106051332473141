import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApiServiceService } from '../-api-service.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

// tslint:disable-next-line:class-name
interface dropValues {
  value: string;
  viewValue: string;
}

export class EResourseHeader  {
  constructor(
    ID: number ,
    SchoolName: string,
    DepartmentName: string,
    NameOfTheTeacher: string,
    ProgrameName: string,
    CourseName: string,
    Semester: string,
    ) {}
}

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {

  constructor(private apiService: ApiServiceService,
              private route: Router) { }

    facultyDataForm = new FormGroup({
      SchoolName : new FormControl(''),
      DepartmentName : new FormControl(''),
      NameOfTheTeacher : new FormControl(''),
      ProgrameName : new FormControl(''),
      CourseName : new FormControl(''),
      Semester : new FormControl(''),
    });

  advancedSearchFlag: boolean;
  rcvData: any;
  schoolNames: dropValues[] = [
    { value: 'School Of Business Studies', viewValue: 'School Of Business Studies' },
    { value: 'School Of Chemical Sciences', viewValue: 'School Of Chemical Sciences' },
    { value: 'School Of Computer Sciences', viewValue: 'School Of Computer Sciences' }
  ];

  departmenNames: dropValues[] = [
    { value: 'Department Of Business Studies', viewValue: 'Department Of Business Studies' },
    { value: 'Department Of Chemistry', viewValue: 'Department Of Chemistry' },
    { value: 'Department Of Computer Sciences', viewValue: 'Department Of Computer Sciences' }
  ];

  teacherNames: dropValues[] = [
    { value: 'Dr.KavitaSangolagi', viewValue: 'Dr.KavitaSangolagi' },
    { value: 'Praveen S K', viewValue: 'Praveen S K' },
    { value: 'Dr. Md Merajuddin', viewValue: 'Dr. Md Merajuddin' },
    { value: 'Dr.VALMIKI', viewValue: 'Dr.VALMIKI' },
    { value: 'Dr. Gururaj', viewValue: 'Dr. Gururaj' },
    { value: 'Dr. Panduranga V', viewValue: 'Dr. Panduranga V' }
  ];

  programNames: dropValues[] = [
    { value: 'BBA', viewValue: 'BBA' },
    { value: 'MBA', viewValue: 'MBA' },
    { value: 'MCA', viewValue: 'MCA' },
    { value: 'M.Com', viewValue: 'M.Com' },
    { value: 'M.Sc Chemistry', viewValue: 'M.Sc Chemistry' },
    { value: 'B.Sc. (PCM)', viewValue: 'B.Sc. (PCM)' }
  ];

  semesters: dropValues[] = [
    { value: '2nd', viewValue: '2nd' },
    { value: '4th', viewValue: '4th' },
    { value: '6th', viewValue: '6th' }
  ];

  courseNames: dropValues[] = [
    { value: 'Business Research', viewValue: 'Business Research' },
    { value: 'Strategic Brand Management', viewValue: 'Strategic Brand Management' },
    { value: 'Society and Business', viewValue: 'Society and Business' },
    { value: 'Business Research Methods', viewValue: 'Business Research Methods' },
    { value: 'Accounting for Managerial Decisions', viewValue: 'Accounting for Managerial Decisions' },
    { value: 'International Financial Management', viewValue: 'International Financial Management' },
    { value: 'Organic Chemistry II', viewValue: 'Organic Chemistry II' },
    { value: 'Chemistry II', viewValue: 'Chemistry II' },
    { value: 'Organic Chemistry', viewValue: 'Organic Chemistry' },
    { value: 'Chemistry Elective', viewValue: 'Chemistry Elective' },
    { value: 'Introduction to Medicinal Chemistry', viewValue: 'Introduction to Medicinal Chemistry' },
    { value: 'Inorganic chemistry', viewValue: 'Inorganic chemistry' },
    { value: 'MCA-406 JAVA PROGRAMMING', viewValue: 'MCA-406 JAVA PROGRAMMING' },
    { value: 'MCA 204.5 IMAGE PROCESSING', viewValue: 'MCA 204.5 IMAGE PROCESSING' },
    { value: 'MCA 204.5 IMAGE PROCESSING', viewValue: 'MCA 204.5 IMAGE PROCESSING' },
  ];

  displayedColumns: string[] = ['SchoolName', 'DepartmentName', 'NameOfTheTeacher', 'ProgrameName', 'CourseName', 'Semester', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  applyFilter(filtervalue: string) {
    console.log(filtervalue);
    const f = filtervalue.trim();
    const ff = filtervalue.toLowerCase();
    this.dataSource.filter = ff;
    console.log(this.dataSource);
  }

  ngOnInit() {
    this.advancedSearchFlag = false;
    this.apiService.getEResourseHeaderDefault()
      .subscribe(objresp => {
        // const eresource = objresp.map(eres =>
        //   // tslint:disable-next-line:max-line-length
        //   new EResourseHeader(eres.ID, eres.SchoolName,
        //     eres.DepartmentName,
        //     eres.NameOfTheTeacher,
        //     eres.ProgrameName,
        //     eres.CourseName,
        //     eres.Semester));
        // this.dataSource =    this.dataSource = new MatTableDataSource(eresource);
        this.rcvData = objresp;
        this.dataSource = new MatTableDataSource(this.rcvData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, err => {
        console.log(err);
      });
    }
  ngAfterViewInit() {
  }

  enableAdvancedSearch() {
    this.advancedSearchFlag = true;
  }

  reset = () => { this.advancedSearchFlag = false; this.facultyDataForm.reset(); };

  onView(Id) {
    this.apiService.getEResourseDetail(Id);
  }

  onSubmit() {
    const data = {
      object : this.facultyDataForm.value
    };
    // tslint:disable-next-line:no-shadowed-variable
    this.apiService.getEResourseHeader(data.object).subscribe(data => {
      this.rcvData = data;
      this.dataSource = new MatTableDataSource(this.rcvData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => {
      console.log(err);
    });
  }
}
