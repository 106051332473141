import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FacultyFilterRoutingModule } from './faculty-filter-routing.module';
import { HomepageComponent } from './homepage/homepage.component';
import { MaterialModule } from '../MaterialModule';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule } from '@angular/common/http';
import { ViewPageComponent } from './view-page/view-page.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HomepageComponent, ViewPageComponent],
  imports: [
    CommonModule,
    FacultyFilterRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  exports:[HomepageComponent],
  providers: [HttpClientModule],
})
export class FacultyFilterModule { }
